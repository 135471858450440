/* eslint-disable no-nested-ternary */
const dev = {
  apiUrl: "http://localhost:3001/api",
  ga: {
    id: "UA-000000000-1",
    debug: false,
  },
  gtm: "GTM-WKJQWJF",
  imgRoot: "https://connect.springboardcollaborative.org",
  baseUrl: "http://localhost:3000",
};

const prod = {
  apiUrl: "https://connect.springboardcollaborative.org/api",
  ga: {
    id: "UA-142218331-1",
    debug: false,
  },
  gtm: "GTM-TL5VW64",
  imgRoot: "",
};

const staging = {
  apiUrl: "https://connect.staging.springboardcollaborative.org/api",
  ga: {
    id: "UA-142218331-2",
    debug: false,
  },
  gtm: "GTM-PVP9FGN",
  imgRoot: "https://connect.springboardcollaborative.org",
};

const pd = {
  apiUrl: "https://connect.pd.springboardcollaborative.org/api",
  ga: {
    id: "UA-142218331-2",
    debug: false,
  },
  gtm: "GTM-TL5VW64",
  imgRoot: "https://connect.springboardcollaborative.org",
};

const e2e = {
  apiUrl: "http://e2e-sb.u2i.eu/api",
  ga: {
    id: "",
    debug: false,
  },
  gtm: "",
  imgRoot: "https://connect.springboardcollaborative.org",
};

const config =
  process.env.REACT_APP_ENV === "production"
    ? prod
    : process.env.REACT_APP_ENV === "staging"
    ? staging
    : process.env.REACT_APP_ENV === "pd"
    ? pd
    : process.env.REACT_APP_ENV === "e2e"
    ? e2e
    : dev;

export default {
  ...config,
};
